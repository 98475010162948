import React from 'react';
import { Link, graphql } from 'gatsby';
import Layout from '../../components/shared/layout';
import SEO from '../../components/SEO/SEO';
import styled from '@emotion/styled';
import { featuredWrapper, noFeaturedWrapper, color, breakpoints, wrapper1200 } from '../../utils/style';
import Img from 'gatsby-image';
//import ButtonLink from '../../components/shared/ButtonLink';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import IcomoonIcon from '../../components/shared/IcomoonIcon';

const FeaturedWrapper = styled('div')`
  ${featuredWrapper}
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  img {
    border-radius: 10px;
  }
  .rich-design {
    position: absolute;
    top: 46%;
    left: 5%;
    margin-top: -35px;
  }
  @media (max-width: ${breakpoints.sm}) {
    .rich-design {
      margin-top: -20px;
    }
  }
`;

const NoFeaturedWrapper = styled('div')`
  ${noFeaturedWrapper}  
`;

const WrapperDesktop = styled('div')`
  ${wrapper1200}
`;

const PostContentWrapper = styled('div')`
  margin: 20px 0;
  .item-wrap {
    width: 48%;
    margin-bottom: 30px;
  }
  .item-content-link {
    &:hover, &:active {
      text-decoration: none;
    }
  }
  .gatsby-image-wrapper {
    border-radius: 10px;
  }
  img {
    border-radius: 10px;
  }
  .img-wrap, .h3 {
    margin-bottom: 10px;
    display: block;
  }
  
  .excerpt {
    margin-bottom: 20px;
    max-height: 164px;
    overflow-y: hidden;
    color: ${color.white};
  }
  
  @media (max-width: ${breakpoints.sm}) {
    .item-wrap { width: 100%; }
    .excerpt {
      font-size: 14px;
      max-height: none;
    }
  }
  

`;

const ContentWrapper = styled('div')`
  margin: 30px 0;
  .gatsby-image-wrapper {
    margin: 20px auto;
  }
  p {
    margin: 15px 0;
  }
  a {
    color: ${color.yellow};
  }
  ol {
      list-style: none;
      counter-reset: li;
      margin: 15px 0;
      padding: 0;
      li {
        counter-increment: li;
        margin-left: -1em;
        &::before {
          content: counter(li);
          display: inline-block;
          width: 1em;
          margin-left: -1.5em;
          margin-right: 0.5em;
          text-align: right;
          direction: rtl
          color: ${color.red};
        }
      }
    }
    ul {
      list-style: none;
      li {
        &::before {
          content: "•";
          color: ${color.red};
          display: inline-block;
          width: 1em;
          margin-right: 5px;
        }
      }
    }
`;




const Category = ({ data, pageContext }) => {
  const currentCategory = data.allWordpressCategory.edges[0].node;
  const metaTitle = (currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_title) ?
    currentCategory.yoast_meta.yoast_wpseo_title : currentCategory.title + "- " + process.env.OWNER;
  const metaDesc = (currentCategory.yoast_meta && currentCategory.yoast_meta.yoast_wpseo_metadesc) ?
    currentCategory.yoast_meta.yoast_wpseo_metadesc : '';
  const breadcrumbs = pageContext.breadcrumbs;
  const page_title = pageContext.page_title;
  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={currentCategory.path.replace('category/','')}
        breadcrumbs={breadcrumbs}
      />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title}/>
        {(currentCategory.acf && currentCategory.acf.category_image) ?
          <FeaturedWrapper>
            <Img fluid={currentCategory.acf.category_image.localFile.childImageSharp.fluid}
                 alt={(currentCategory.acf.category_image.alt_text) ? currentCategory.acf.category_image.alt_text :
                   currentCategory.name}/>
            <h1 className="rich-design big-header">
              <span>{currentCategory.name}</span>
            </h1>
          </FeaturedWrapper>
          : <NoFeaturedWrapper>
            <h1 dangerouslySetInnerHTML={{ __html: currentCategory.name }}/>
          </NoFeaturedWrapper>
        }

        {currentCategory.description &&
        <ContentWrapper>
          <div dangerouslySetInnerHTML={{ __html: currentCategory.description }}/>
        </ContentWrapper>
        }

        <PostContentWrapper className="flex">
          {data.allWordpressPost.edges.map(({ node }) => (
            <div className="item-wrap" key={node.slug}>
              <Link className="item-content-link" to={"/" + node.slug + "/"}>
                {(node.featured_media && node.featured_media.localFile && node.featured_media.localFile.childImageSharp.fluid) ?
                <div className="img-wrap">
                  <Img fluid={node.featured_media.localFile.childImageSharp.fluid}
                       alt={node.featured_media.alt_text ? node.featured_media.alt_text : node.title}/>
                </div>
                : <div className="img-wrap">
                     <Img fluid={data.placeholder.childImageSharp.fluid} alt={node.title}/>
                  </div>
                }
                <span className="h3">{node.title}</span>
                <div className="excerpt" dangerouslySetInnerHTML={{ __html: node.excerpt }}/>
                <span className="button gradient-button">
                  อ่านเพิ่มเติม
                  <IcomoonIcon icon="arrow-right" color={color.trueBlack} size={18}/>
                </span>
              </Link>
            </div>
          ))}
        </PostContentWrapper>
      </WrapperDesktop>
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    allWordpressCategory(
      filter: { wordpress_id: {eq: $wordpress_id} }
    ) {
      edges {
        node {
          id
          name
          slug
          description
          path
          link
            acf {
              category_image {
                alt_text
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1400) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            yoast_meta {
              yoast_wpseo_title
              yoast_wpseo_metadesc
            }
          }
        }
    }
    allWordpressPost(
      sort: { fields:date, order: DESC },
        filter: {
          categories: {
            elemMatch: {
              wordpress_id: {eq:$wordpress_id}
            }
          }
        }
      ) {
      edges {
        node {
          title
          excerpt
          slug
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
    placeholder: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }  
`;
